import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { IUserSearchResponse, IUserSearchObj } from '@User/adminuser/user-extras/user.interface';
import { ISearchResponse2 } from '@Shared/models/index.model';
import { ApiService } from 'ets-fe-ng-sdk';
import { map, tap, retry } from 'rxjs/operators';
import { ISearchResponse } from '@Shared/components/search/search-extras/search.interface';
import { ITeamMember } from '../interfaces/app.interface';

@Injectable({
  providedIn: 'root',
})
export class TeamsService {
  retryCount = 3;
  baseURL = environment.apiBaseUrl + '/rest';
  constructor(private apiService: ApiService) {}

  getUserSearch(query: IUserSearchObj) {
    return this.apiService.get<IUserSearchResponse>(`${this.baseURL}/users/search`, query);
  }

  getAgentSearch(query: any) {
    // debugger
    return this.apiService.get<ISearchResponse2>(this.baseURL + '/agent/search', query);
  }

  getMemberView(memberCode: string) {
    return this.apiService.get(this.baseURL + `/sales/view/member/${memberCode}`);
  }

  searchTeam = (query?: any) => {
    return this.apiService
      .get<any>(this.baseURL + '/sales/team/search', query)
      .pipe(map((res) => res.content as any[]));
  };

  searchMember(query: any) {
    return this.apiService
      .get<any>(this.baseURL + '/sales/team/member/search', query)
      .pipe(map((res) => res.content as any[]));
  }

  searchMemberByteamCode = (teamCode: string) => {
    return this.apiService
      .get<ISearchResponse2<ITeamMember>>(this.baseURL + '/sales/team/member/search', { teamCode })
      .pipe(map((res) => res.content?.sort2('memberCode')));
  };
  /**
   * Calls the endpoint thats fetched team members by team code
   * @param teamCode
   * @returns
   */
  searchMemberByteamCode2 = (teamCode: string) => {
    return this.apiService.get(`${this.baseURL}/sales/team/member/search`, { teamCode }).pipe(
      retry(this.retryCount),
      map((res) =>
        res.content.map((r: any) => {
          r['code'] = r.memberCode;
          r['description'] = r.memberName;
          return r;
        }),
      ),
    );
  };

  getTeamDetails(code: string) {
    return this.apiService.get(this.baseURL + `/sales/team/code/details/${code}`);
  }

  getTeamLead = () => {
    return this.apiService.get<any[]>(this.baseURL + `/sales/team/search`);
  };

  getTeamLeads() {
    return this.apiService.get(this.baseURL + `/sales/search`);
  }
  getPersonnelName(personnelCode: string) {
    return this.apiService.get(this.baseURL + `/sales/search?code=${personnelCode}`);
  }

  getPersonnelName2(personnelCode: string) {
    return this.apiService.get(this.baseURL + `/sales/team/member/search?memberCode=${personnelCode}`);
  }

  submitTeamCode = (data: any) => {
    return this.apiService.post<any>(this.baseURL + 'sales/team/code', data);
  };
}
